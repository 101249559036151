import {
	FullTextSearchAnd2DSphereIndexNameMap,
	collection_name,
	fullTextSearchAnd2DSphereIndexName_type,
} from "../collations/fullTextSearchAnd2DSphereIndexNameMap";

export const getFullTextSearchAnd2DSphereIndexName: (
	collection: collection_name,
) => fullTextSearchAnd2DSphereIndexName_type = (collection: collection_name) => {
	return FullTextSearchAnd2DSphereIndexNameMap.get(collection) as fullTextSearchAnd2DSphereIndexName_type;
};
