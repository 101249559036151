export type collection_name = "properties" | "services" | "business_profiles" | "users" | "posts";

export type fullTextSearchAnd2DSphereIndexName_type =
	| "propertyFullTextAnd2DSphere"
	| "serviceFullTextAnd2DSphere"
	| "businessProfileFullTextAnd2DSphere"
	| "userFullTextAnd2DSphere"
	| "postFullTextAnd2DSphere";


export const FullTextSearchAnd2DSphereIndexNameMap = new Map<collection_name, fullTextSearchAnd2DSphereIndexName_type>([
	["properties", "propertyFullTextAnd2DSphere"],
	["services", "serviceFullTextAnd2DSphere"],
	["business_profiles", "businessProfileFullTextAnd2DSphere"],
	["users", "userFullTextAnd2DSphere"],
	["posts", "postFullTextAnd2DSphere"],
]);

